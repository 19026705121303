const HOTELPROVIDERNAME = {
  ostrovok: 'Островок',
  'bronevik-online': 'Броневик онлайн',
  academ: 'Академсервис',
  expedia: 'Expedia',
  tripcom: 'Trip.com',
  direct: 'Direct',
  aanda: 'A&A',
  hotelbook: 'hotelbook',
  booking: 'Booking.com',
  CustomProvider: 'Кастомный провайдер',
  alpaka: 'Альпака',
  travelline: 'TravelLine',
  contract: 'Contract',
  sawady3d: 'Sawady3D',
  alpaka3d: 'Alpaka3D',
  verdeho3d: 'Verdeho3D',
  verdeho: 'Verdeho',
  satguru: 'Satguru',
  alvist: 'Alvist',
  goGlobalTravel: 'Go Global Travel',
  MyMice: 'Мой майс',
  MyMiceAGENT: 'Мой майс АГЕНТ',
};

const HOTEL_PROVIDER_VALUE = {
  ostrovok: 'ostrovok',
  'bronevik-online': 'bronevik-online',
  bronevik: 'bronevik',
  academ: 'academ',
  expedia: 'expedia',
  tripcom: 'tripcom',
  direct: 'direct',
  aanda: 'aanda',
  hotelbook: 'hotelbook',
  booking: 'booking',
  alpaka: 'alpaka',
  travelline: 'travelline',
  contract: 'contract',
  sawady3d: 'sawady3d',
  alpaka3d: 'alpaka3d',
  verdeho3d: 'verdeho3d',
  verdeho: 'verdeho',
  satguru: 'satguru',
  alvist: 'alvist',
  goGlobalTravel: 'goGlobalTravel',
  MyMice: 'MyMice',
  MyMiceAGENT: 'MyMiceAGENT',
};

const PROVIDERS_AVAILABLE_INN = [
  'direct',
  'booking',
  'alpaka',
  'contract',
  'verdeho',
  'alpaka3d',
  'verdeho3d',
  'sawady3d',
];

const HOTEL_PROVIDER_IS_AUTO_CALC_VAT = [
  HOTEL_PROVIDER_VALUE.alpaka,
  HOTEL_PROVIDER_VALUE.sawady3d,
  HOTEL_PROVIDER_VALUE.alpaka3d,
  HOTEL_PROVIDER_VALUE.verdeho3d,
];

const ROOM_TYPE = {
  WITHOUT_CANCELLATION_AND_BREAKFAST: 1,
  BREAKFAST_WITHOUT_CANCELLATION: 2,
  CANCELLATION_WITHOUT_BREAKFAST: 3,
  WITH_CANCELLATION_AND_BREAKFAST: 4,
};

const FIELDS_VAT = {
  RATE: 'Rate',
  RACK_RATE: 'RackRate',
  AMOUNT: 'Amount',
};

const CURRENCY_VALUES = {
  USD: 'USD',
};

export {
  HOTELPROVIDERNAME as default,
  HOTELPROVIDERNAME,
  HOTEL_PROVIDER_VALUE,
  PROVIDERS_AVAILABLE_INN,
  ROOM_TYPE,
  HOTEL_PROVIDER_IS_AUTO_CALC_VAT,
  FIELDS_VAT,
  CURRENCY_VALUES,
};
