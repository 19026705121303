const PASSPORT = {
  DOMESTICPASSPORT: 'DomesticPassport',
  FOREIGNLABELPASSPORT: 'Паспорт иностранного гражданина:',
  FOREIGNPASSPORT: 'ForeignPassport',
  PASSPORTRF: 'Паспорт РФ:',
  INTERNATIONPASSPORT: 'Загран. паспорт:',
  PASSPORTERROR: 'Модель документа не соответствует заданной',
};

const CODE = {
  RU: 'RU',
  RUSSIA: 'Россия',
  /** Беларусь */
  BY: 'BY',
};

const ENUMS = {
  BONUS: 'bonus',
  CITIZENSHIP: 'citizenship',
};

const TITLE = {
  COMMON: 'ОБЩЕЕ',
  EMPLOYEE_NAME: 'ФИО:',
  DATE_BIRTHDAY: 'Дата рождения:',
  NATIONALITY: 'Гражданство:',
  EMPLOYEE_SEX: 'Пол:',
  EMPLOYEE_NUMBER: 'Табельный номер сотрудника:',
  PROJECT: 'Центр затрат по умолчанию:',
  EMPLOYEE_CONTACTS: 'КОНТАКТЫ:',
  EMAIL: 'Email:',
  PHONE_NUMBER: 'Телефон:',
  ACCOUNTS: 'АККАУНТЫ',
  ACCOUNT_NAME: 'Название аккаунта:',
  ACCOUNT__LINK: 'Аккаунт Сотрудника:',
  SHORT_COMPANY_LIST: 'Список коротких компаний:',
  EMPLOYEE_DOCUMENTS: 'ДОКУМЕНТЫ',
  EMPLOYEE_TRAVELPOLICY: 'TRAVEL ПОЛИТИКИ',
  ALLOW_RAVELPOLICY: 'Разрешено:',
  EMPLOYEE_RIGHT: 'ПРАВА',
  EMPLOYEE_SCHEME: 'СХЕМА СОГЛАСОВАНИЯ',
  EMPLOYEE_TRIPS: 'ПОЕЗДКИ',
  EMPLOYEE_TAGS: 'Метки:',
  EMPLOYEE_CAN: 'Пользователь может:',
  EMPLOYEE_CANT: 'Пользователь не может:',
  NOT_RESTRICTIONS: 'Ограничений нет',
  EXCEPTIONS: 'Исключения:',
  NANE: 'Имя',
  DESCRIPTION: 'Описание:',
  NOTES: 'Заметки',
  DEPARTMENTS: 'Отделы:',
  NOT_AVAILABLE_VIP_HALL: 'запретить покупку Vip-залов',
};

const TYPES_ICON_LIST = {
  CAN_LOGIN: {
    TYPEICON: 'done_outline',
    TOOLTIPTEXT: 'Может входить в сервис',
  },
  ARCHIVED: {
    TYPEICON: 'archive',
    TOOLTIPTEXT: 'Пользователь в Архиве',
  },
};

const ANCHORS = {
  DOCUMENT: 'document',
  ACCOUNTS: 'accaunts',
  CONTACTS: 'contacts',
  GENERAL: 'general',
  RIGHT: 'right',
  SCHEME: 'scheme',
  TRIP: 'trip',
  TP: 'tp',
  BONUS_CARDS: 'bonusCards',
};

export {
  PASSPORT,
  CODE,
  TITLE,
  TYPES_ICON_LIST,
  ANCHORS,
  ENUMS,
};
