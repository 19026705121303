
const LINK_TEXT_KEYS = {
  EMPLOYEE_KEY: 'employee_key',
  ACCOUNT_KEY: 'account_key',
};

const LINK_TEXT = {
  ACTIVE: {
    [LINK_TEXT_KEYS.EMPLOYEE_KEY]: 'Перейти в карточку сотрудника',
    [LINK_TEXT_KEYS.ACCOUNT_KEY]: 'Перейти в аккаунт сотрудника',
  },
  DISABLED: 'Ссылка не определена',
};

const LINK_ICONS = {
  ACTIVE: 'link',
  DISABLED: 'link_off',
};

const DEFAULT_NAME = 'Не определено';

export {
  LINK_TEXT,
  LINK_TEXT_KEYS,
  LINK_ICONS,
  DEFAULT_NAME,
};
