
const TIME_SESSION = [
    { label: '10 мин', value: 600 },
    { label: '20 мин', value: 1200 },
    { label: '30 мин', value: 1800 },
    { label: '60 мин', value: 3600 },
];

const PASSWORDGENERATOR_TIME_SESSION = [
  { label: '60 мин', value: 3600 },
];

export { TIME_SESSION, PASSWORDGENERATOR_TIME_SESSION };
