const AMADEUS_ORDER_IDS = {
  MOWR228AX: 'MOWR228AX',
  MOWR228UD: 'MOWR228UD',
  NUEL121W5: 'NUEL121W5',
};

const MIXVEL_POINT_IDS = {
  POINT_92177912: '92177912',
  POINT_92300154: '92300154',
};

const AIRPROVIDERS = {
  TMC: {
    TYPE: 'TMC',
    VALUE: 'TMC',
    ORDER_ID: null,
  },
  PORTBILET: {
    TYPE: 'Portbilet',
    VALUE: 'Портбилет',
    ORDER_ID: null,
  },
  TKP: {
    TYPE: 'TKP',
    VALUE: 'ТКП',
    ORDER_ID: null,
  },
  AZIMUT: {
    TYPE: 'Azimut',
    VALUE: 'Azimut',
    ORDER_ID: null,
  },
  ETS: {
    TYPE: 'ETS',
    VALUE: 'ETS',
    ORDER_ID: null,
  },
  UZBEK: {
    TYPE: 'UzbekistanAirways',
    VALUE: 'Uzbekistan Airways - прямой сток',
    ORDER_ID: null,
  },
  SSEVEN: {
    TYPE: 'S7',
    VALUE: 'S7',
    ORDER_ID: null,
  },
  AMADEUS: {
    TYPE: 'Amadeus',
    VALUE: 'Амадеус',
    ORDER_ID: [
      { value: AMADEUS_ORDER_IDS.MOWR228AX, label: AMADEUS_ORDER_IDS.MOWR228AX },
      { value: AMADEUS_ORDER_IDS.MOWR228UD, label: AMADEUS_ORDER_IDS.MOWR228UD },
      { value: AMADEUS_ORDER_IDS.NUEL121W5, label: AMADEUS_ORDER_IDS.NUEL121W5 },
    ],
  },
  AVIACENTR: {
    TYPE: 'aviacentr',
    VALUE: 'Авиацентр',
    ORDER_ID: null,
  },
  ANGARA: {
    TYPE: 'Angara',
    VALUE: 'Ангара',
    ORDER_ID: null,
  },
  AEROFLOT: {
    TYPE: 'Aeroflot',
    VALUE: 'Аэрофлот',
    ORDER_ID: null,
  },
  TAIS: {
    TYPE: 'Tais',
    VALUE: 'Tais',
    ORDER_ID: null,
  },
  MIXVEL: {
    TYPE: 'Mixvel',
    VALUE: 'Миксвел',
    SALE_ID: [
      { value: MIXVEL_POINT_IDS.POINT_92177912, label: MIXVEL_POINT_IDS.POINT_92177912 },
      { value: MIXVEL_POINT_IDS.POINT_92300154, label: MIXVEL_POINT_IDS.POINT_92300154 },
    ],
    ORDER_ID: null,
  },
};

export default AIRPROVIDERS;
export { AMADEUS_ORDER_IDS };
