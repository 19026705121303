const LABELS_EDIT = {
  TO_CART: 'к корзине',
  DEFAULT_DESCRIPTION: 'Новая услуга',
  ADD_ORDER: 'ДОБАВИТЬ ЗАКАЗ',
};

const ERRORS = {
  NO_SELECT_APPROVERS: 'Заполните согласующих для каждого шага',
};

const CARTSTATUS = {
  APPROVED: 'Approved',
  DECLINED: 'Declined',
  WAITINGAPPROVE: 'WaitingApprove',
  NORMAL: 'Normal',
};

const APPROVE_STATUS = {
  WAITING_APPROVE: 'На согласовании',
  APPROVED: 'Согласовано',
  DECLINED: 'Отказано',
};

const ERRORS_KEYS = {
  INVALID_CART_DATA: 'InvalidCartData',
  NOT_ENOUGH_EMPLOYEES: 'NotEnoughEmployees',
  INVALID_EMPLOYEE: 'InvalidEmployee',
  INVALID_EMPLOYEE_COMPANY: 'InvalidEmployeeCompany',
  INVALID_EMPLOYEE_DEPARTMENT: 'InvalidEmployeeDepartment',
  INVALID_EMPLOYEE_PROJECT: 'InvalidEmployeeProject',
  NOT_ENOUGH_REQUIRED_USER_ANALYTICS: 'NotEnoughRequiredUserAnalytics',
  HOTEL_INVALID_GUESTS_COUNT: 'HotelInvalidGuestsCount',
  HOTEL_INVALID_DATES: 'HotelInvalidDates',
  HOTEL_INVALID_HOTEL_INFO: 'HotelInvalidHotelInfo',
  HOTEL_INVALID_PRICES: 'HotelInvalidPrices',
};

const VALIDATION_ERRORS = {
  [ERRORS_KEYS.INVALID_CART_DATA]: 'Неизвестная ошибка',
  [ERRORS_KEYS.NOT_ENOUGH_EMPLOYEES]: 'Необходимо выбрать сотрудника',
  [ERRORS_KEYS.INVALID_EMPLOYEE]: 'Выбран неверный сотрудник',
  [ERRORS_KEYS.INVALID_EMPLOYEE_COMPANY]: 'Необходимо выбрать компанию',
  [ERRORS_KEYS.INVALID_EMPLOYEE_DEPARTMENT]: 'Необходимо выбрать отдел',
  [ERRORS_KEYS.INVALID_EMPLOYEE_PROJECT]: 'Необходимо выбрать центр затрат',
  [ERRORS_KEYS.NOT_ENOUGH_REQUIRED_USER_ANALYTICS]: 'Необходимо выбрать пользовательские аналитики',
  [ERRORS_KEYS.HOTEL_INVALID_GUESTS_COUNT]: 'Неверное количество гостей',
  [ERRORS_KEYS.HOTEL_INVALID_DATES]: 'Неверные даты заезда/выезда',
  [ERRORS_KEYS.HOTEL_INVALID_HOTEL_INFO]: 'Не указаны данные об отеле',
  [ERRORS_KEYS.HOTEL_INVALID_PRICES]: 'Неверные суммы заказа (цена поставщика и коммиссия должна быть больше 0)',
};

const STEP_CONDITION_VALUES = {
  ALL_APPROVERS: 'AllApprovers',
};

const VALIDATE_INTERCOM_LINK = 'В поле должна быть указана ссылка на чат с клиентом';

const TIMEOUT = 100;

export {
  LABELS_EDIT,
  ERRORS,
  CARTSTATUS,
  APPROVE_STATUS,
  VALIDATION_ERRORS,
  STEP_CONDITION_VALUES,
  ERRORS_KEYS,
  VALIDATE_INTERCOM_LINK,
  TIMEOUT,
};
