const LABELS = {
  HOTEL_NAME: 'Название отеля',
  TYPE_OF_VAT: 'Тип НДС',
  GUESTS_AMOUNT: 'Количество гостей',
  GUEST_FULL_NAME: 'ФИО Гостя',
  COST_PER_DAY: 'Стоимость рублей/сутки',
  CHECKIN_DATE: 'Дата заезда',
  CHECKIN_TIME: 'Время заезда',
  CHECKOUT_DATE: 'Дата выезда',
  CHECKOUT_TIME: 'Время выезда',
  FREE_CANCELLATION_DATE: 'Дата снятия без потерь',
  ROOM_CATEGORY: 'Категория комнаты',
  EARLY_CHECKIN: 'Ранний заезд',
  LATE_CHECKOUT: 'Поздний выезд',
  BREAKFAST: 'Завтрак включен',
  ADD_TO_ORDER: 'Включить в поездку',
  FREE_CANCELLATION: 'Бесплатная отмена бронирования',
  PRICE: 'Стоимость руб.',
  DAILY_PRICE: 'Стоимость рублей/сутки',
  EMAIL: 'Укажите Email',
  PHONE: 'Укажите контактный номер',
  ADDITIONAL_EMAIL: 'Дополнительный Email',
  COMMENT: 'Дополнительный комментарий:',
  SAVED_REQUESTS_HEADER: 'Заявки на бронирование',
  NO_SAVED_REQUESTS: 'Нет сохраненных заявок',
  INTERCOM_LINK: 'Ссылка на чат Intercom',
  NEW_TRIP: 'Создать поездку',
  SELF_EMPLOYED: 'Самозанятый',
  REQUEST_STATUS: 'Статус заявки',
  TOTAL_FOR_CLIENT: 'Сумма (для клиента)',
  TOTAL_FOR_SMARTWAY: 'Сумма (для Smartway)',
  ADDITIONAL: 'Не видит клиент',
  PENALTY_FROM: 'C',
  SELECT_USER: 'Выберите инициатора',
  INITIATOR: 'Инициатор поездки',
  CUSTOM_ANALYTICS: 'Пользовательская аналитика',
  NEW_TRIP_WAITING: 'Пожалуйста подождите, поездка создается, после создания она будет открыта в новом окне',
  SENDING_REQUEST: 'Заявка отправляется...',
};

const NOTIFICATION_STATE = {
  SUCCESS: 'SUCCESS',
  FAILURE: 'FAILURE',
};

const WARNINGS = {
  DATE_WARNING: 'Дата выезда не может быть раньше даты заезда',
  DAYS_TO_BOOK: 'Количество дней для бронировния не должно быть менее 1',
  GUESTS_WARNING: 'Количество выбранных гостей не соответствует количеству выбранных сотрудников',
  NO_CONTACTS: 'Данные о контактах отсутствуют, необходимо заполнить адрес эл. почты и номер телефона вручную',
  NO_EMAIL: 'Необходимо указать адрес эл.почты',
  SUCCESS: 'Заявка сохранена',
  FAILURE: 'Не удалось сохранить заявку',
  SEND_SUCCESS: 'Заявка отправлена в отель',
  SEND_FAILURE: 'Не удалось отправить заявку в отель',
  NO_INTERCOM_LINK: 'Необходимо указать ссылку на чат Intercom',
  NO_DAILY_PRICE: 'Необходимо указать стоимость рублей/сутки',
  NO_CUSTOMERS: 'Необходимо выбрать минимум 1 гостя',
  NO_HOTEL_SELECTED: 'Необходимо выбрать отель',
  SUCCESS_EMAIL_SUBJ: (email, emailSubj) => `Заявка отправлена на почту ${email} с темой: ${emailSubj}`,
  NEW_TRIP_FAILURE: 'Не удалось создать поездку',
  NEW_TRIP_SUCCESS: 'Поездка создана',
  NO_INITIATOR: 'Необходимо указать инициатора поездки',
  HOTEL_NOT_FOUND: 'Не удалось найти отель с таким названием, пожалуйста, внесите отель в базу',
  CHECK_BEFORE_SEND: 'Пожалуйста, проверьте данные перед отправкой',
};

const BUTTON_LABELS = {
  SEND: 'Отправить заявку',
  RESET: 'Очистить форму',
  SAVE_DRAFT: 'Сохранить заявку',
  SAVE_EDITED: 'Сохранить изменения',
  LOAD_MORE: 'Показать больше',
  HIDE: 'Скрыть',
  MOVE_TO_CART: 'Перейти в корзину',
};

const DATE_FIELDS = {
  CHECKIN_DATE: 'checkinDate',
  CHECKOUT_DATE: 'checkoutDate',
  CHECKIN_TIME: 'checkinTime',
  CHECKOUT_TIME: 'checkoutTime',
  FREE_CANCELLATION_DATE: 'freeCancellationDate',
  CANCELLATION_DATE_WITH_FEE: 'cancellationDateWithFee',
};

const CONTACTS_FIELDS = {
  EMAIL: 'email',
  PHONE: 'phone',
  ADDITIONAL_EMAIL: 'additionalEmail',
};

const GUESTS = [
  { label: '1', value: 1 },
  { label: '2', value: 2 },
  { label: '3', value: 3 },
  { label: '4', value: 4 },
];

const VAT_VALUES = [
  { label: 'Без НДС', value: 0 },
  { label: 'Включен в цену', value: 1 },
  { label: '20%', value: 2 },
];

const VAT_LABELS = {
  withoutVat: 'Без НДС',
  vatIncluded: 'Включен в цену',
  worksWithVat: '20%',
};

const PRICE_VALUES = {
  earlyCheckinPrice: 'earlyCheckinPrice',
  lateCheckoutPrice: 'lateCheckoutPrice',
  breakfastPrice: 'breakfastPrice',
  dailyPrice: 'dailyPrice',
  cancellationFeeTotal: 'cancellationFeeTotal',
  cancellationFeeBase: 'cancellationFeeBase',
};

const THEME = {
  BORDER: 'border',
  LIGHT: 'light',
};

const WRAP_STYLE = { width: 'calc(100%/5)' };

const INPUT_STYLE = {
  background: 'transparent',
  position: 'relative',
  zIndex: 5,
  border: '1px solid #ccc',
  width: '100%',
  height: '100%',
  padding: '2px',
  color: 'inherit',
  boxSizing: 'borderBox',
  font: 'inherit',
};

const INPUT_PROPS = {
  placeholder: LABELS.HOTEL_NAME,
  style: INPUT_STYLE,
  type: 'search',
  required: true,
};

const TEXT_AREA_HEIGHT = 100;

const HEADER_LABELS = {
  intercomId: 'Id чата Intercom',
  hotelName: 'Отель',
  vatType: 'Тип НДС',
  employees: 'Сотрудники',
  checkinDate: 'Дата заезда',
  checkoutDate: 'Дата выезда',
  status: 'Статус',
};

const REQUEST_STATUS_LABELS = {
  Incomplete: 'не завершено',
  Pending: 'ожидает подтверждения',
  Confirmed: 'подтверждена',
  TripAdded: 'поездка создана',
};

const REQUEST_STATUSES = [
  { value: 0, label: 'ожидает подтверждения' },
  { value: 1, label: 'подтверждена' },
];

const INTERCOM_LINK = {
  PROD: conversationId => `https://app.intercom.com/a/inbox/l3shlkm4/inbox/conversation/${conversationId}?view=List`,
  TEST: conversationId => `https://app.intercom.com/a/inbox/a37ozakz/inbox/shared/all/conversation/${conversationId}?view=List`,
};

const REQUIRED_EXTENSIONS = ['docx'];

const UPLOAD_FORM = {
  DESCRIPTION: 'Перетащите или',
  INVALID_EXTENSION: 'Некорректное расширение файла. Доступные для загрузки:',
  CHOOSE_FILE: 'Выберите файл',
};

const BOOK_REQUEST = {
  ID: 'bookRequest',
  HOTEL_BOOK_REQUEST: 'Заявка на бронь отеля',
};

const COMMISSION = {
  FIELD: 'commission',
  LABEL: 'Комиссия Smartway',
};

const URL_QUERY_PARAMS = {
  CART_ID: 'cartId',
  REQUEST_ID: 'requestId',
};

const TIMEOUT = 10000;

export {
  LABELS,
  NOTIFICATION_STATE,
  WARNINGS,
  BUTTON_LABELS,
  DATE_FIELDS,
  CONTACTS_FIELDS,
  GUESTS,
  VAT_VALUES,
  PRICE_VALUES,
  THEME,
  WRAP_STYLE,
  INPUT_STYLE,
  INPUT_PROPS,
  HEADER_LABELS,
  VAT_LABELS,
  REQUEST_STATUS_LABELS,
  INTERCOM_LINK,
  REQUIRED_EXTENSIONS,
  UPLOAD_FORM,
  TEXT_AREA_HEIGHT,
  BOOK_REQUEST,
  REQUEST_STATUSES,
  COMMISSION,
  URL_QUERY_PARAMS,
  TIMEOUT,
};
