const DECISIONBUTTONS = {
  LABELS: {
    CONFIRM: 'Подтвердить',
    SAVE: 'Сохранить',
    CANCEL: 'Отменить',
    DELETEANYWAY: 'Удалить все равно',
    DELETE: 'Удалить',
    MOVEANYWAY: 'Перенести все равно',
    MOVE: 'Перенести',
    CHANGEANYWAY: 'Все равно изменить',
    DELETE_ITEMS: 'Да, удалить поездку',
    DELETE_DOCUMENT: 'Да, удалить документ',
    CONFIRM_CANCELLATION: 'Подтверждаю аннуляцию',
    CANCEL_DIALOG: 'Закрыть сообщение',
    DOWNLOAD: 'Загрузить',
    CHANGE_ANYWAY: 'Изменить все равно',
  },
};

const DECISION_BUTTONS_CAMEL = {
  LABELS: {
    CONFIRM: 'ПОДТВЕРДИТЬ',
    SAVE: 'СОХРАНИТЬ',
    CANCEL: 'ОТМЕНИТЬ',
    DELETE: 'УДАЛИТЬ',
    EDIT: 'ИЗМЕНИТЬ',
  },
};

export {
  DECISIONBUTTONS,
  DECISION_BUTTONS_CAMEL,
};
