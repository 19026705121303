const ARCHIVED = 'Archived';

const INSURANSE = {
  CLIENTISINSURENCE: 'Клиент застрахован:',
  STARTDATEINSURANCE: 'Дата начала страхования:',
  ENDDATEINSURANCE: 'Дата окончания страхования:',
  NOINDICATED: 'не указано',
  AVAILABLE: 'Да',
  UNAVAILABLE: 'Нет',
  TOOLTIPETEXT: 'укажите застрахован ли клиент и дату начала страхования',
  TOOLTIPREASONREUIRED: 'Не заполнено поле "Причина отзыва лимита"',
  REASONFORCANCELLATIONOFINSURANCE: 'Причина отзыва лимита',

};

const SETTINGS_FIELD = {
  PROHIBITION_VOUCHERS: 'prohibitionVouchers',
  REMOVE_FLIGHT_CERTIFICATE: 'removeFlightCertificate',
  IS_SHOW_REPORTING: 'isShowReporting',
  // FREE_FLIGHT_CERTIFICATE: 'freeFlightCertificate', // TODO: вернуть когда попросит бизнес
};

const LABEL_SETTINGS_FIELDS = {
  [SETTINGS_FIELD.PROHIBITION_VOUCHERS]: 'Запрет на отправку ваучеров',
  [SETTINGS_FIELD.REMOVE_FLIGHT_CERTIFICATE]: 'Запрет на покупку справок о перелете',
  [SETTINGS_FIELD.IS_SHOW_REPORTING]: 'Закрыть доступ к разделу Отчетность',
  // [SETTINGS_FIELD.FREE_FLIGHT_CERTIFICATE]: 'Справки о перелете без финансовых операций', // TODO: вернуть когда попросит бизнес
};

const STATUS_EXPENSE_REPORTS = {
  Created: 'Created',
  WaitingApprove: 'WaitingApprove',
  Approved: 'Approved',
  Declined: 'Declined',
  WaitingSend1C: 'WaitingSend1C',
  ErrorSend1C: 'ErrorSend1C',
  Send1C: 'Send1C',
  Deleted: 'Deleted',
};

const STATUS_EXPENSE_REPORTS_RU = {
  [STATUS_EXPENSE_REPORTS.Created]: 'Создан',
  [STATUS_EXPENSE_REPORTS.WaitingApprove]: 'Отправлен на согласование',
  [STATUS_EXPENSE_REPORTS.Approved]: 'Согласован',
  [STATUS_EXPENSE_REPORTS.Declined]: 'Не согласован',
  [STATUS_EXPENSE_REPORTS.WaitingSend1C]: 'Ожидает отправки в 1С',
  [STATUS_EXPENSE_REPORTS.ErrorSend1C]: 'Ошибка отправки в 1С',
  [STATUS_EXPENSE_REPORTS.Send1C]: 'Отправлен в 1С',
  [STATUS_EXPENSE_REPORTS.Deleted]: 'Удален',
};

const STATUS_EVENTS = {
  Cancelled: 'Cancelled',
  Created: 'Created',
  Booked: 'Booked',
  Compleded: 'Completed',
};

const SORTING_EVENTS = {
  DATE: 'Date',
  NAME: 'Name',
  STATUS: 'Status',
};

const LABELS_STATUS_EVENTS = {
  [STATUS_EVENTS.Created]: 'Создана',
  [STATUS_EVENTS.Cancelled]: 'Отменена',
  [STATUS_EVENTS.Booked]: 'Оформлена',
  [STATUS_EVENTS.Compleded]: 'Завершена',
};

const SEARCH_STATUSES_EVENTS = [
  STATUS_EVENTS.Created,
  STATUS_EVENTS.Booked,
  STATUS_EVENTS.Cancelled,
];

const VAT_RATE = {
  WITH_VAT: 20,
  WITHOUT_VAT: 0,
};

export {
  ARCHIVED,
  INSURANSE,
  SETTINGS_FIELD,
  LABEL_SETTINGS_FIELDS,
  STATUS_EXPENSE_REPORTS,
  STATUS_EXPENSE_REPORTS_RU,
  STATUS_EVENTS,
  LABELS_STATUS_EVENTS,
  SEARCH_STATUSES_EVENTS,
  SORTING_EVENTS,
  VAT_RATE,
};
